import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private http: HttpClient
  ) { }

  send(visitId: string) {
    const body = { visitId, type: 'REMINDER', message: 'sample notification' };
    return this.http.post<any>(`${environment.baseApi}/v1/notification/notify`, body);
  }

  getAll() {
      return this.http.get<any[]>(`${environment.baseApi}/v1/appointments`).pipe(
          map(notifications => notifications.filter(notification => !!notification.contact).map(notification => ({ ...notification, id: notification.pragmatistid })))
      )
  }
}
