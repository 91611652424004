
/**
 * !!! GENERATED CODE !!!
 *
 * Do not modify code here as it will be overriden by 'serve' or 'build' commands.
 * Rather modify 'TARGET' entry in './config/.env' file and edit './config/${TARGET}.env' file accordingly. 
 */

export const environment = {
    target: 'fsrecette',
    appName: 'JLD_Avantages',
    appVersion: '1.0.0',
    buildDate: 'Wed May 18 2022 16:20:08 GMT+0200 (Central European Summer Time)',
    production: false,
    langs: 'fr,en',
    indexDbName: 'fscontributionrecette',
    indexDbVersion: '1',
    baseApi: 'https://fabiosalsa-rdv-recette.zento.fr/api',
    devApiKey: 'Bearer A2h2YK4jp6DjhxLMbrNvhSsTMHHew4qRDTHP1KAnXavK'
};
